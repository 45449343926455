import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    redirect: { name: "dashboard" },
    component: () => import("@/components/LayoutView.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardView.vue"),
      },
      {
        path: "table/:id",
        name: "table",
        component: () => import("@/views/TableView.vue"),
      },
      {
        path: "table/:id/checkout",
        name: "checkout",
        component: () => import("@/views/CheckoutView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
