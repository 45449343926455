import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth.module";
import table from "@/store/modules/table.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    table,
  },
});
