import Vue from "vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import store from "@/store";
import ApiService from "@/service/api.service";
import AppMixin from "@/mixins/app.mixin";
import VueMask from "v-mask";

Vue.use(AppMixin);

Vue.use(VueMask);

Vue.config.productionTip = false;

ApiService.init();

Vue.prototype.$assetURL = (param) => {
  return `${process.env.VUE_APP_BASE_URL}${param}`;
};

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
