import CurrencyJS from "currency.js";

const validate_email = (email) => {
  //eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

class MainSetting {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          v_loading: false,
          v_form_valid: true,
          v_messages: {
            error: [],
            success: [],
          },
          v_rules: {
            required(val, field) {
              return !!val || `${field} is required`;
            },
            valid_email(val, field) {
              if (val) {
                if (validate_email(val)) {
                  return true;
                }
                return `${field} must be valid`;
              }
              return true;
            },
            valid_numeric(val, field) {
              if (val) {
                if (/^\d+$/.test(val)) {
                  return true;
                }
                return `${field} must be valid (only digits) & greater than 0`;
              }
              return true;
            },
          },
        };
      },
      methods: {
        formatMoney(param) {
          return CurrencyJS(param, {
            symbol: "RM ",
            separator: ",",
            decimal: ".",
          }).format();
        },
        formatNumber(param) {
          return CurrencyJS(param, {
            symbol: "",
            separator: ",",
            decimal: ".",
          }).format();
        },
      },
    });
  }
}

export default new MainSetting();
