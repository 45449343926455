import ApiService from "@/service/api.service";
import ObjectPath from "object-path";

// action types
export const INITIAL = "getInitial";
export const TABLE = "getTable";
export const PRODUCT = "getProduct";
export const CATEGORY = "getCategory";
export const ADD_ON_CATEGORY = "getAddOnCategory";
export const ITEM = "getItem";
export const UPDATE_ITEM = "getUpdateItem";
export const DELETE_ITEM = "deleteItem";
export const UPDATE_QUANTITY_ITEM = "updateQuantityItem";
export const UPDATE_COMMENT_ITEM = "updateCommentItem";
export const UPDATE_ADDON_ITEM = "updateAddonItem";
export const UPDATE_DISCOUNT = "updateDiscount";
export const GET_CUSTOMER = "getCustomer";
export const CREATE_CUSTOMER = "createCustomer";
export const GET_CUSTOMER_VOUCHER = "getCustomerVoucher";
export const APPLY_VOUCHER = "applyVoucher";
export const REMOVE_VOUCHER = "removeVoucher";

// mutation types
export const SET_INITIAL = "setInitial";
export const SET_TABLE = "setTable";
export const SET_PRODUCT = "setProduct";
export const SET_CATEGORY = "setCategory";
export const SET_ADD_ON_CATEGORY = "setAddOnCategory";
export const SET_ITEM = "setItem";
export const SET_CUSTOMER = "setCustomer";
export const SET_VOUCHER = "setVoucher";

// state
const state = {
  tables: [],
  products: [],
  categories: [],
  add_ons: [],
  items: [],
  table: {},
  voucher: {},
  customers: [],
  vouchers: [],
  is_initialized: false,
  walkin: {
    id: 1,
    address_city: null,
    address_country: null,
    address_line_1: null,
    address_line_2: null,
    address_name: null,
    address_postal_code: null,
    address_state: null,
    barcode: "WALK-IN",
    country: "65",
    display_name: "WALK IN CUSTOMER",
    email: null,
    first_name: null,
    image: null,
    last_name: null,
    phone_number: null,
    title: null,
    is_walkin: true,
  },
};

// getters
const getters = {
  is_initialized(state) {
    return state.is_initialized;
  },
  walkin(state) {
    return state.walkin;
  },
  tables(state) {
    return state.tables;
  },
  products(state) {
    return state.products;
  },
  categories(state) {
    return state.categories;
  },
  add_ons(state) {
    return state.add_ons;
  },
  items(state) {
    return state.items;
  },
  table(state) {
    return state.table;
  },
  customers(state) {
    return state.customers;
  },
  voucher(state) {
    return state.voucher;
  },
  vouchers(state) {
    return state.vouchers;
  },
};

// actions
const actions = {
  [INITIAL](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query("initial", params)
        .then((response) => {
          context.commit(SET_INITIAL, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TABLE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query("tables", params)
        .then((response) => {
          context.commit(SET_TABLE, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PRODUCT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query("products", params)
        .then((response) => {
          context.commit(SET_PRODUCT, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CATEGORY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.query("categories", params)
        .then((response) => {
          context.commit(SET_CATEGORY, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_ON_CATEGORY](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.query("add-on-categories", params)
            .then((response) => {
              context.commit(SET_ADD_ON_CATEGORY, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ITEM](context, { table }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.get(`table/${table}/items`)
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_ITEM](context, { table, item }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.patch(`table/${table}/items`, item)
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_ITEM](context, { table, table_item }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.delete(`table/${table}/item/${table_item}`)
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_QUANTITY_ITEM](context, { table, table_item, quantity }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.patch(`table/${table}/item/${table_item}/quantity`, {
            quantity,
          })
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_COMMENT_ITEM](context, { table, table_item, comment }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.patch(`table/${table}/item/${table_item}/comment`, {
            comment,
          })
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_ADDON_ITEM](context, { table, table_item, add_ons }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.patch(`table/${table}/item/${table_item}/add-on`, {
            add_ons,
          })
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_DISCOUNT](context, { table, d_level, d_type, d_value, d_items }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.patch(`table/${table}/discount`, {
            d_level,
            d_type,
            d_value,
            d_items,
          })
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_CUSTOMER](context, { search }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.query(`customer`, { search })
            .then((response) => {
              context.commit(SET_CUSTOMER, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_CUSTOMER_VOUCHER](context, customer) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.get(`customer/${customer}/vouchers`)
            .then((response) => {
              context.commit(SET_VOUCHER, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [APPLY_VOUCHER](context, { table, voucher }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.patch(`table/${table}/voucher/${voucher}`)
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REMOVE_VOUCHER](context, { table }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.delete(`table/${table}/voucher`)
            .then((response) => {
              context.commit(SET_ITEM, response);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CREATE_CUSTOMER](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader()
        .then(() => {
          ApiService.post(`customer`, params)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  [SET_INITIAL](state, payload) {
    state.is_initialized = true;
    state.tables = ObjectPath.get(payload, "tables", []);
    state.products = ObjectPath.get(payload, "products", []);
    state.categories = ObjectPath.get(payload, "categories", []);
    state.add_ons = ObjectPath.get(payload, "add_ons", []);
  },
  [SET_CUSTOMER](state, payload) {
    state.customers = payload;
  },
  [SET_TABLE](state, payload) {
    state.tables = payload;
  },
  [SET_PRODUCT](state, payload) {
    state.products = payload;
  },
  [SET_CATEGORY](state, payload) {
    state.categories = payload;
  },
  [SET_ITEM](state, payload) {
    state.items = payload.items;
    state.table = payload.table;
    state.voucher = payload.voucher;
  },
  [SET_ADD_ON_CATEGORY](state, payload) {
    state.add_ons = payload;
  },
  [SET_VOUCHER](state, payload) {
    state.vouchers = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
