import LocalService from "@/service/local.service";

const ID_USER = "_btxusr";
const ID_TOKEN_KEY = "access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expires_at";

export const saveToken = (token) => {
  LocalService.saveLocalStorage(ID_TOKEN_KEY, token);
};

export const getToken = () => {
  return LocalService.getLocalStorage(ID_TOKEN_KEY);
};

export const saveTokenType = (type) => {
  LocalService.saveLocalStorage(ID_TOKEN_TYPE, type);
};

export const getTokenType = () => {
  return LocalService.getLocalStorage(ID_TOKEN_TYPE);
};

export const saveTokenExpiry = (expiry) => {
  LocalService.saveLocalStorage(ID_TOKEN_EXPIRY, expiry);
};

export const getTokenExpiry = () => {
  return LocalService.getLocalStorage(ID_TOKEN_EXPIRY);
};

export const saveUser = (user) => {
  LocalService.saveLocalStorageJSON(ID_USER, user);
};

export const getUser = () => {
  return LocalService.getLocalStorageJSON(ID_USER);
};

export const destroyAuth = () => {
  LocalService.destroyLocalStorage(ID_USER);
  LocalService.destroyLocalStorage(ID_TOKEN_KEY);
  LocalService.destroyLocalStorage(ID_TOKEN_TYPE);
  LocalService.destroyLocalStorage(ID_TOKEN_EXPIRY);
};

export default {
  getToken,
  saveToken,
  saveTokenType,
  getTokenType,
  saveTokenExpiry,
  getTokenExpiry,
  saveUser,
  getUser,
  destroyAuth,
};
