import { Storage } from "@capacitor/storage";
import ObjectPath from "object-path";

export const getLocalStorage = async (key) => {
  const output = await Storage.get({ key });
  return ObjectPath.get(output, "value");
};

export const saveLocalStorage = async (key, value) => {
  await Storage.set({ key, value });
};

export const destroyLocalStorage = async (key) => {
  await Storage.remove({ key });
};

export const getLocalStorageJSON = async (key) => {
  const output = await getLocalStorage(key);
  return JSON.parse(output);
};

export const saveLocalStorageJSON = async (key, value) => {
  const input = JSON.stringify(value);
  await saveLocalStorage(key, input);
};

export default {
  getLocalStorage,
  saveLocalStorage,
  destroyLocalStorage,
  getLocalStorageJSON,
  saveLocalStorageJSON,
};
