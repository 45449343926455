<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<style lang="scss">
// Main style scss
@import "assets/sass/style.vue";
</style>

<script>
export default {
  name: "main-app",
  data() {
    return {};
  },
  mounted() {},
};
</script>
