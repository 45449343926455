import ApiService from "@/service/api.service";
import JwtService from "@/service/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "logIn";
export const LOGOUT = "logOut";

// mutation types
export const PURGE_AUTH = "purgeAuth";
export const SET_AUTH = "setUser";

// state
const state = {
  loggedIn: {},
  isAuthenticated: !!JwtService.getToken(),
};

// getters
const getters = {
  loggedIn(state) {
    return state.loggedIn;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

// actions
const actions = {
  [LOGIN](context, credentials) {
    context.commit(PURGE_AUTH);
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then((response) => {
          context.commit(SET_AUTH, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    ApiService.setHeader();
    ApiService.post("logout").then(() => {
      context.commit(PURGE_AUTH);
    });
  },
};

// mutations
const mutations = {
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.loggedIn = data.user;
    JwtService.saveUser(data.user);
    JwtService.saveToken(data.access_token);
    JwtService.saveTokenType(data.token_type);
    JwtService.saveTokenExpiry(data.expires_at);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.loggedIn = {};
    JwtService.destroyAuth();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
